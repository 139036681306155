import React from 'react'
import {css} from '@emotion/react'
import {useElementSize} from '@kensho/tacklebox'
import {MDXProvider} from '@mdx-js/react'
import {graphql, PageProps} from 'gatsby'
import {MDXRenderer} from 'gatsby-plugin-mdx'

import NavigationProvider from '../providers/NavigationProvider'
import Cards from '../components/Cards'
import {articleMaxWidth} from '../constants'
import VersionProvider from '../providers/VersionProvider'
import {BaseVersionedPageContext, Mdx, PageEntry} from '../types'

import PageWithNav from './PageWithNav'

const articleCss = css`
  margin: auto;
  margin-top: 20px;
  max-width: ${articleMaxWidth}px;
  padding: 10px;
`

interface SectionOverviewProps extends PageProps {
  data: {
    mdx: Mdx
  }
  pageContext: BaseVersionedPageContext & {
    section: string
    pages: PageEntry[]
  }
}

export const pageQuery = graphql`
  query SectionOverviewMarkdownQuery($id: String) {
    mdx(id: {eq: $id}) {
      id
      body
      tableOfContents
    }
  }
`

export default function SectionOverview({
  data: {mdx},
  pageContext: {version, versions, navList, section, pages, pageConfig},
  location,
}: SectionOverviewProps): JSX.Element | null {
  const [{width}, sizeRef] = useElementSize()

  let cardsSize = 'lg'
  if (width < 700) {
    cardsSize = 'sm'
  } else if (width < 800) {
    cardsSize = 'md'
  }

  return (
    <VersionProvider versions={versions} version={version}>
      <NavigationProvider navList={navList} tableOfContents={mdx?.tableOfContents.items}>
        <PageWithNav location={location} pageConfig={pageConfig}>
          <article ref={sizeRef} css={articleCss}>
            {mdx ? (
              <MDXProvider>
                <MDXRenderer>{mdx.body}</MDXRenderer>
              </MDXProvider>
            ) : (
              <h3>{section}</h3>
            )}
            <Cards
              className={cardsSize}
              items={pages}
              pathPrefix={version?.latest ? '' : `/${version?.identifier}`}
            />
          </article>
        </PageWithNav>
      </NavigationProvider>
    </VersionProvider>
  )
}
