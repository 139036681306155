import React from 'react'
import {css, SerializedStyles} from '@emotion/react'
import {navigate} from 'gatsby'

import normalizePath from '../utils/normalizePath'

import {NavEntry} from './nav/types'

const IE_MAX_GRID_ITEMS = 50

// IE <=11 has it's own special prefixed grid behavior
// emotion (stylis under the hood) does not autoprefix grid
// autoprefixer's grid: autoplace requires explicit grid-template-columns AND grid-template-rows
// generate nth-child placement rules for a reasonable amount of items
const generateGridPlacementCss = (
  numColumns: number,
  maxItems = IE_MAX_GRID_ITEMS
): SerializedStyles => {
  const styleObj: {[selector: string]: unknown} = {}
  let i = 1
  while (i <= maxItems) {
    styleObj[`& > li:nth-of-type(${i})`] = {
      msGridColumn: `${i % numColumns || numColumns}`,
      msGridRow: `${Math.ceil(i / numColumns)}`,
    }
    i += 1
  }

  return css(styleObj)
}

const IEGridCss = css`
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr;

  ${generateGridPlacementCss(3)}

  .md & {
    -ms-grid-columns: 1fr 1fr;
    ${generateGridPlacementCss(2)}
  }

  .sm & {
    -ms-grid-columns: 1fr;
    ${generateGridPlacementCss(1)}
  }
`

const gridCss = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  list-style: none;
  margin: 0;

  .md & {
    grid-template-columns: repeat(2, 1fr);
  }

  .sm & {
    grid-template-columns: repeat(1, 1fr);
  }

  ${IEGridCss}
`

const gridItemCss = css`
  padding: 10px;
`

const cardCss = css`
  display: inline-block;
  padding: 15px;
  background: #ffffff;
  border: 1px solid rgba(16, 22, 26, 0.1);
  border-radius: 3px;
  transition: 0.1s linear;
  display: flex;
  height: 100%;
  min-height: 110px;
  width: 100%;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 2px 6px rgba(16, 22, 26, 0.2);
  }
`

const titleCss = css`
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 4px;
`

const descriptionCss = css`
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 0;
`

interface CardsProps {
  className?: string
  title?: string
  items: NavEntry[]
  pathPrefix?: string
}

export default function Cards({className, title, items, pathPrefix = ''}: CardsProps): JSX.Element {
  return (
    <div className={className}>
      {title && <h3>{title}</h3>}
      <ul css={gridCss}>
        {items.map((item) => (
          <li key={item.url} css={gridItemCss}>
            <div
              css={cardCss}
              onClick={() => {
                navigate(normalizePath(`${pathPrefix}${item.url}`))
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') navigate(normalizePath(`${pathPrefix}${item.url}`))
              }}
            >
              <h4 css={titleCss}>{item.title}</h4>
              {!!item.description && <p css={descriptionCss}>{item.description}</p>}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
